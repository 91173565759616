import { Link } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { PageContext } from '../context/ApplicationState'
import Container from './Container'
import Layout from './Layout'
import SEO from './SEO'
import StickyContainer from './StickyContainer'

const RecognitionLayout = ({ location, children, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: false,
      hasHeroSection: true,
    }))
  }, [])

  return (
    <Layout {...props}>
      <SEO />
      <StickyContainer
        className="fill--nearly-white"
        stickyNavLinks={
          <>
            <Link
              to="/recognition/honor-roll/"
              activeClassName="active-page-link"
            >
              Honor Roll
            </Link>
            <Link
              to="/recognition/top-products/"
              activeClassName="active-page-link"
            >
              Top Products
            </Link>
            <Link
              to="/recognition/seints-club/"
              activeClassName="active-page-link"
            >
              Seint's Club
            </Link>
            <Link
              to="/recognition/legacy-leaders/"
              activeClassName="active-page-link"
            >
              Legacy Leaders
            </Link>
          </>
        }
      >
        <Container className="padded-section">{children}</Container>
      </StickyContainer>
    </Layout>
  )
}

export default RecognitionLayout
